@use 'sass:math';

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.col,
.col_1,
.col_2,
.col_3,
.col_4,
.col_5,
.col_6,
.col_7,
.col_8,
.col_9,
.col_10,
.col_11,
.col_12,
.col_offset_0,
.col_offset_1,
.col_offset_2,
.col_offset_3,
.col_offset_4,
.col_offset_5,
.col_offset_6,
.col_offset_7,
.col_offset_8,
.col_offset_9,
.col_offset_10,
.col_offset_11,
.col_offset_12 {
  flex: 0 0 auto;
  padding-right: 5px;
  padding-left: 5px;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

// Replace percentage() with math.div() function
@mixin col_width($fraction) {
  flex-basis: percentage($fraction);
  max-width: percentage($fraction);
}

.col_1 { @include col_width(math.div(1, 12)); }
.col_2 { @include col_width(math.div(1, 6)); }
.col_3 { @include col_width(math.div(1, 4)); }
.col_4 { @include col_width(math.div(1, 3)); }
.col_5 { @include col_width(math.div(5, 12)); }
.col_6 { @include col_width(math.div(1, 2)); }
.col_7 { @include col_width(math.div(7, 12)); }
.col_8 { @include col_width(math.div(2, 3)); }
.col_9 { @include col_width(math.div(3, 4)); }
.col_10 { @include col_width(math.div(5, 6)); }
.col_11 { @include col_width(math.div(11, 12)); }
.col_12 { flex-basis: 100%; max-width: 100%; }

// Replace percentage() with math.div() function
@mixin col_offset($fraction) {
  margin-left: percentage($fraction);
}

.col_offset_0 { @include col_offset(0); }
.col_offset_1 { @include col_offset(math.div(1, 12)); }
.col_offset_2 { @include col_offset(math.div(1, 6)); }
.col_offset_3 { @include col_offset(math.div(1, 4)); }
.col_offset_4 { @include col_offset(math.div(1, 3)); }
.col_offset_5 { @include col_offset(math.div(5, 12)); }
.col_offset_6 { @include col_offset(math.div(1, 2)); }
.col_offset_7 { @include col_offset(math.div(7, 12)); }
.col_offset_8 { @include col_offset(math.div(2, 3)); }
.col_offset_9 { @include col_offset(math.div(3, 4)); }
.col_offset_10 { @include col_offset(math.div(5, 6)); }
.col_offset_11 { @include col_offset(math.div(11, 12)); }

.top { align-items: flex-start; }
.middle { align-items: center; }
.bottom { align-items: flex-end; }
.start { justify-content: flex-start; text-align: start; }
.center { justify-content: center; text-align: center; }
.end { justify-content: flex-end; text-align: end; }
.around { justify-content: space-around; }
.between { justify-content: space-between; }
.first { order: -1; }
.last { order: 1; }
.shrink { flex: 0 0 auto; max-width: 100%; }
