@import '~app/grid';
@import '~app/mixins';

$color-disabled: #d3d3d3;
$offset: 10px;

.root {
  padding: 0 13px 15px;
  font-size: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 240px);

  input[type='text'],
  select {
    max-width: 100px;

    &[disabled] {
      border-color: $color-grey;
      background-color: $color-disabled;
    }
  }
}

.label {
  margin-bottom: 12px;
  line-height: 14px;
  font-size: 12px;
  color: $color-blue-darkest;

  &.mbSmall {
    margin-bottom: 3px;
  }
}

.symbol {
  display: inline-block;
  vertical-align: top;
  line-height: 26px;
  padding-right: 5px;
  font-size: 12px;
  color: $color-grey-1;

  &.after {
    padding-right: 0;
    padding-left: 5px;
  }
}

.inlinesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inlinesColumn {
  .inline {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.tableColumn {
  margin-top: 10px;

  button {
    margin-right: 20px;
  }

  table {
    margin-top: 20px;
  }
}

.inputRow {
  .inputColumn {
    margin-top: 10px;
  }
}

.inline {
  display: flex;
  flex: 0 0 auto;
  flex-basis: 29%;
  max-width: 29%;
  margin-top: 11px;

  .inlineControl {
    display: inline-flex;
    flex-direction: row;
    flex-basis: 100%;
    width: 100%;
    height: 26px;
  }

  label {
    flex: 1 0 0;
    margin-bottom: 0;
    padding-right: 2px;
    font-size: 12px;
    color: $color-grey-1;

    @include text-overflow();
  }

  > div {
    width: 118px;
  }

  .symbol {
    width: 14px;
  }
}

.price {
  line-height: 26px;
  font-size: 14px;
  font-weight: 500;
  color: $color-grey;
}

.results {
  margin: 10px;
  font-size: 12px;

  &Container {
    border: 1px solid $color-filter-border;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    padding: 16px 28px;
    line-height: 17px;
    background-color: $color-dirty-white;
  }

  &Caption {
    font-size: 14px;
    font-weight: 500;
    color: $color-grey;
  }

  &Controls {
    display: inline-flex;
  }

  &Control {
    margin-left: 30px;
    color: $color-blue-medium;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
  }

  &Body {
    padding: 0 28px 23px;
    font-size: 12px;
    color: $color-grey-1;
  }

  &Item {
    margin-top: 14px;
  }

  &Color {
    color: $color-blue-darkest;
  }

  &Buttons {
    text-align: right;
  }

  &Button {
    margin-left: 10px;
    padding: 8px 34px;
    font-size: 11px;
    line-height: 12px;
    color: $color-grey;
  }
}

.mortgageHeader {
  padding: 16px 14px;
  background-color: $color-dirty-white;
  font-size: 16px;
  color: $color-grey;
}

.flexContainer {
  display: inline-flex;
  align-items: center;
  margin-left: 30px;

  &:first-child {
    margin-left: 0;
  }

  input {
    margin-left: 5px;
  }
}

.dropdownContainer > div {
  width: 105px;
}

.depreciationTypeRow {
  input {
    margin-top: 4px;
  }
}

.date {
  width: 115px;
  max-width: 115px;

  input[type='text'] {
    width: 115px;
    max-width: 115px;
  }
}

.subLabel {
  color: #777;
  line-height: 18px;
}

.report {
  width: calc(50% - #{$offset*2});
  border: 2px solid $color-grey-lighter;
  background-color: $color-white;
  text-align: center;
  padding: 15px 10px;
  margin: 20px $offset 0;
  display: inline-block;
  border-radius: 10px;

  .btn {
    min-width: 135px;
  }

  .caption {
    color: $color-blue-dark;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
  }

  .description {
    color: $color-grey;
    font-size: 11px;
    font-style: italic;
    line-height: 13px;
    margin: 10px;
    min-height: 30px;
  }
}

